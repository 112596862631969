import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { mainBlue, screen } from "../components/common/variables";
import { flatten, toSlug } from "../components/helpers";
import styled from "styled-components";
import { StoreContext } from "../context";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import PageTitle from "../components/page-title";
import Tabs from "../components/products/tabs/foundry-tab";
import ProductSidebar from "../components/products/sidebar";
import GetInTouch from "../components/get-in-touch";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }
  @media ${screen.xlarge} {
    padding-bottom: 200px;
  }

  .banner-sm {
    @media ${screen.xsmall} {
      display: none;
    }
  }

  .inner-wrapper {
    @media ${screen.xsmall} {
      padding-left: 70px;
      padding-right: 0;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }
  }

  .accessories {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-right: 70px;
      padding-left: 0;
      padding-top: 50px;
    }
    @media ${screen.small} {
      padding-right: 0;
      padding-top: 0;
    }
    @media ${screen.xlarge} {
    }

    &__heading {
      margin-bottom: 9px;
      line-height: 1.25;
      @media ${screen.xsmall} {
        margin-bottom: 35px;
      }
      @media ${screen.xlarge} {
        margin-bottom: 45px;
      }
    }
  }

  .furnace-tools {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-left: 0;
      padding-right: 0;
    }
    @media ${screen.small} {
      flex-direction: row;
    }
    @media ${screen.xlarge} {
      padding-top: 90px;
    }

    &__text {
      color: ${mainBlue};
      max-width: 660px;

      .heading {
        font-size: 1.7rem;
        margin-bottom: 10px;
        @media ${screen.xsmall} {
          font-size: 2.3rem;
          margin-bottom: 14px;
        }
        @media ${screen.xlarge} {
          font-size: 2.5rem;
          margin-bottom: 16px;
        }
      }

      .description {
        p {
          font-size: 1.08rem;
          margin-bottom: 18px;
          @media ${screen.xsmall} {
            font-size: 1.08rem;
            margin-bottom: 22px;
          }
          @media ${screen.xlarge} {
            font-size: 1.25rem;
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .contact {
        margin-top: 250px;
        margin-bottom: 150px;
        display: none;
        @media ${screen.xsmall} {
        }
        @media ${screen.small} {
          display: block;
        }
        @media ${screen.xlarge} {
          margin-bottom: 180px;
        }
      }
    }

    .contact--sm {
      margin-bottom: 90px;
      margin-top: 90px;
      @media ${screen.xsmall} {
        margin-bottom: 120px;
        margin-top: 0;
      }
      @media ${screen.small} {
        display: none;
      }
    }

    &__img {
      max-width: 610px;
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
      position: relative;
      @media ${screen.xsmall} {
        max-width: 300px;
        margin-left: calc(20px + 40%);
      }
      @media ${screen.small} {
        max-width: 610px;
        margin-left: 40px;
        margin-top: 0;
      }

      .main-featured-img {
        max-width: 210px;
        @media ${screen.xsmall} {
          max-width: 380px;
        }
        img {
        }
      }

      .small-featured-imgs {
        position: absolute;
        top: 100px;
        right: 0;
        max-width: 150px;
        width: 100%;
        @media ${screen.xsmall} {
          top: 190px;
          right: -60px;
          max-width: 200px;
        }
        @media ${screen.small} {
          right: 0;
          max-width: 310px;
        }

        &__each {
          margin-bottom: 8px;
          @media ${screen.xsmall} {
            margin-bottom: 22px;
          }
        }
      }
    }
  }

  .related-blogs {
    @media ${screen.xsmall} {
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-right: 0;
    }
  }
`;

const FoundryProductsPage = ({ data }) => {
  const {
    addOrder,
    selectedProduct,
    setProductSidebar,
    isProductSidebarOpen,
    setProduct,
    addUrlSearch,
    removeUrlSearch,
    urlSearch,
    orders,
  } = useContext(StoreContext);

  const urlPath =
    typeof window !== `undefined` && window.location.pathname.substring(1);

  const {
    main_heading,
    product_list,
    featured_image,
    featured_image_mobile,
    ladles_description,
    all_products_description,
    skimmers_description,
    tongs_description,
    degassing_plungers_description,
    furnace_tools_heading,
    furnace_tools_description,
    furnace_tools_featured_image,
    furnace_tools_small_image_1,
    furnace_tools_small_image_2,
    furnace_tools_small_image_3,
    title_tag,
    meta_description,
  } = data.main.data;

  const allProducts = product_list.map((item) => {
    return {
      node: {
        type: item.product.type,
        id: item.product.document.id,
        data: item.product.document.data,
      },
    };
  });

  const ladles = allProducts.filter(
    (item) => item.node.type === "ladle_foundry"
  );
  const skimmers = allProducts.filter(
    (item) => item.node.type === "skimmer_foundry"
  );
  const tongs = allProducts.filter(
    (item) => item.node.type === "tongs_foundry"
  );
  const plungers = allProducts.filter(
    (item) => item.node.type === "degassing_plunger_foundry"
  );

  const products = flatten([ladles, skimmers, tongs, plungers]);

  useEffect(() => {
    products.forEach((product) => {
      if (toSlug(product.node.data.name.text) === urlSearch) {
        setProduct({
          id: product.node.id,
          name: product.node.data.name.text,
          description:
            product.node.data.description && product.node.data.description.html,
          images: product.node.data.images.length && product.node.data.images,
          column1Heading:
            product.node.data.column_1_heading &&
            product.node.data.column_1_heading.text,
          column2Heading:
            product.node.data.column_2_heading &&
            product.node.data.column_2_heading.text,
          column3Heading:
            product.node.data.column_3_heading &&
            product.node.data.column_3_heading.text,
          column4Heading:
            product.node.data.column_4_heading &&
            product.node.data.column_4_heading.text,
          column5Heading:
            product.node.data.column_5_heading &&
            product.node.data.column_5_heading.text,
          column6Heading:
            product.node.data.column_6_heading &&
            product.node.data.column_6_heading.text,
          column1Subheading: product.node.data.column_1_subheading,
          column2Subheading: product.node.data.column_2_subheading,
          column3Subheading: product.node.data.column_3_subheading,
          column4Subheading: product.node.data.column_4_subheading,
          column5Subheading: product.node.data.column_5_subheading,
          column6Subheading: product.node.data.column_6_subheading,
          tableRows: product.node.data.rows,
          relatedProducts: product.node.data.product_list,
        });
        setProductSidebar(true);
        return;
      }
    });
  }, [urlSearch]);

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="banner-sm">
          {featured_image_mobile.gatsbyImageData && (
            <GatsbyImage
              image={featured_image_mobile.gatsbyImageData}
              alt={featured_image.alt || "Banner"}
            />
          )}
        </div>
        <div className="inner-wrapper">
          <section className="accessories" id="foundry-products">
            <PageTitle className="accessories__heading">
              {main_heading}
            </PageTitle>
            <Tabs
              foundry={{
                product_list: allProducts,
                ladles,
                skimmers,
                tongs,
                plungers,
                all_products_description,
                ladles_description,
                skimmers_description,
                tongs_description,
                degassing_plungers_description,
              }}
              addUrlSearch={addUrlSearch}
              urlSearch={urlSearch}
              setProductSidebar={setProductSidebar}
              setProduct={setProduct}
              addOrder={addOrder}
              urlPath={urlPath}
            />
            <ProductSidebar
              isProductSidebarOpen={isProductSidebarOpen}
              setProduct={setProduct}
              selectedProduct={selectedProduct}
              setProductSidebar={setProductSidebar}
              removeUrlSearch={removeUrlSearch}
              addUrlSearch={addUrlSearch}
              addOrder={addOrder}
              orders={orders}
              productType={"foundry"}
              urlPath={urlPath}
              products={allProducts}
            />
          </section>

          <section className="furnace-tools">
            <div className="furnace-tools__text">
              <h2 className="heading">{furnace_tools_heading}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: furnace_tools_description.html,
                }}
              />
              <div className="contact contact--lg">
                <GetInTouch
                  heading={`Contact ATLC`}
                  subheading={`Interested in customising your products?`}
                  linkLabel={`TELL US MORE`}
                />
              </div>
            </div>
            <div className="furnace-tools__img">
              <div className="main-featured-img">
                {furnace_tools_featured_image.gatsbyImageData && (
                  <GatsbyImage
                    image={furnace_tools_featured_image.gatsbyImageData}
                    alt={furnace_tools_featured_image.alt || "Featured"}
                  />
                )}
              </div>
              <div className="small-featured-imgs">
                <div className="small-featured-imgs__each">
                  {furnace_tools_small_image_1.gatsbyImageData && (
                    <GatsbyImage
                      image={furnace_tools_small_image_1.gatsbyImageData}
                      alt={furnace_tools_small_image_1.alt || "Featured"}
                    />
                  )}
                </div>
                <div className="small-featured-imgs__each">
                  {furnace_tools_small_image_2.gatsbyImageData && (
                    <GatsbyImage
                      image={furnace_tools_small_image_2.gatsbyImageData}
                      alt={furnace_tools_small_image_2.alt || "Featured"}
                    />
                  )}
                </div>
                <div className="small-featured-imgs__each">
                  {furnace_tools_small_image_3.gatsbyImageData && (
                    <GatsbyImage
                      image={furnace_tools_small_image_3.gatsbyImageData}
                      alt={furnace_tools_small_image_3.alt || "Featured"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="contact contact--sm">
              <GetInTouch
                heading={`Contact ATLC`}
                subheading={`Interested in customising your products?`}
                linkLabel={`TELL US MORE`}
              />
            </div>
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default FoundryProductsPage;

export const dataQuery = graphql`
  {
    main: prismicFoundryProductsPage {
      data {
        product_list {
          product {
            type
            document {
              ... on PrismicLadleFoundry {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicDegassingPlungerFoundry {
                          id
                        }
                        ... on PrismicLadleFoundry {
                          id
                        }
                        ... on PrismicSkimmerFoundry {
                          id
                        }
                        ... on PrismicTongsFoundry {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicTongsFoundry {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicDegassingPlungerFoundry {
                          id
                        }
                        ... on PrismicLadleFoundry {
                          id
                        }
                        ... on PrismicSkimmerFoundry {
                          id
                        }
                        ... on PrismicTongsFoundry {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicSkimmerFoundry {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicDegassingPlungerFoundry {
                          id
                        }
                        ... on PrismicLadleFoundry {
                          id
                        }
                        ... on PrismicSkimmerFoundry {
                          id
                        }
                        ... on PrismicTongsFoundry {
                          id
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicDegassingPlungerFoundry {
                id
                data {
                  name {
                    text
                  }
                  description {
                    html
                  }
                  images {
                    image {
                      alt
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                  column_1_heading {
                    text
                  }
                  column_2_heading {
                    text
                  }
                  column_3_heading {
                    text
                  }
                  column_4_heading {
                    text
                  }
                  column_5_heading {
                    text
                  }
                  column_6_heading {
                    text
                  }
                  column_1_subheading
                  column_2_subheading
                  column_3_subheading
                  column_4_subheading
                  column_5_subheading
                  column_6_subheading
                  rows {
                    column_1_data
                    column_2_data
                    column_3_data
                    column_4_data
                    column_5_data
                    column_6_data
                  }
                  product_list {
                    product {
                      document {
                        ... on PrismicDegassingPlungerFoundry {
                          id
                        }
                        ... on PrismicLadleFoundry {
                          id
                        }
                        ... on PrismicSkimmerFoundry {
                          id
                        }
                        ... on PrismicTongsFoundry {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        main_heading
        main_subheading
        main_description {
          html
        }
        featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        featured_image_mobile {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        ladles_description {
          html
        }
        all_products_description {
          html
        }
        skimmers_description {
          html
        }
        tongs_description {
          html
        }
        degassing_plungers_description {
          html
        }
        furnace_tools_heading
        furnace_tools_description {
          html
        }
        furnace_tools_featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        furnace_tools_small_image_1 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        furnace_tools_small_image_2 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        furnace_tools_small_image_3 {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        title_tag
        meta_description
      }
    }
  }
`;
